import mainStore from '@/store'
import ordersModule from '@/store/main/orders/transfer'

export default function config() {
  // Constants
  const MODULE_NAME = 'transfer-orders'
  const ORDERS_STATUS_READY_STATUS = 1
  const ORDERS_STATUS_REJECTED = 5
  const ORDERS_STATUS_AS_DRAFT = 1
  const ORDERS_STATE_CLOSED_ORDER = 2
  const store = mainStore
  const model = ordersModule

  const tableColumns = [
    {
      key: 'order_id',
      label: 'Order No.',
      thStyle: { width: '10%' },
      isSortable: true,
    },
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '15%' },
      isSortable: true,
    },
    {
      key: 'from',
      label: 'From',
      thStyle: { width: '10%' },
      isSortable: true,
    },
    {
      key: 'to',
      label: 'To',
      thStyle: { width: '10%' },
      isSortable: true,
    },
    {
      key: 'scheduled_dispatch',
      label: 'Scheduled Dispatch',
      thStyle: { width: '15%' },
      isSortable: true,
    },
    {
      key: 'items',
      label: 'Item Ct',
      thStyle: { width: '10%', textAlign: 'center' },
      isSortable: true,
    },
    {
      key: 'order_date',
      label: 'Order Date',
      thStyle: { width: '30%' },
      isSortable: true,
    },
  ]

  const orderStatus = [
    {
      icon: 'LTransferCalendarIcon',
      query: 'pick',
    },
    {
      icon: 'LPickIcon',
      query: 'in-box',
    },
    {
      icon: 'LBoxIcon',
      query: 'in-box',
    },
    {
      icon: 'LTruckIcon',
      query: 'on-truck',
    },
    {
      icon: 'LWarehouseIcon',
      query: 'in-warehouse',
    },
  ]

  return {
    store,
    model,
    MODULE_NAME,
    tableColumns,
    orderStatus,
    ORDERS_STATUS_READY_STATUS,
    ORDERS_STATUS_REJECTED,
    ORDERS_STATUS_AS_DRAFT,
    ORDERS_STATE_CLOSED_ORDER,
  }
}
