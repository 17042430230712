var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-1"},[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/list"),
    },"row-clicked":_vm.navigateToItem},scopedSlots:_vm._u([{key:"cell(order_id)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'order_id', '—'))+" ")]}},{key:"cell(state)",fn:function(ref){
    var item = ref.data.item;
return [_c('status-filter-table',{attrs:{"item":item}})]}},{key:"cell(from)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'shipping_warehouse_label', '—'))+" ")]}},{key:"cell(scheduled_dispatch)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'scheduled_dispatch', '—'))+" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'transfer_time', '—'))+" ")]}},{key:"cell(to)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'receiving_warehouse_label', '—'))+" ")]}},{key:"cell(items)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'items', '—'))+" ")])]}},{key:"cell(order_date)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'created_at', '—'))+" ")])]}},{key:"table-top-right-side-extras-prev",fn:function(){return [_c('status-filter-color-key')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }